import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import '../components/reglement.css';

const Busreglement = () => (
  <Layout>
    <SEO title="Busreglement" />
    <div className="reglement">
        <h2 className="reglement-title">Werkingsreglement</h2>
        <ol>
            <li>Je mag jezelf lid van Supportersclub Leuven Lads noemen vanaf het moment dat je de gevraagde gegevens hebt doorgegeven via internet of via een fysiek inschrijvingsmoment <br/>EN<br/> wanneer je het lidgeld hebt betaald via overschrijving of cash aan iemand van de leden van het bestuur. </li>
            <li>Ieder jaar wordt het lidgeld op een vergadering vastgelegd voor het komende seizoen. Indien er noodzaak is dit lidgeld te verhogen zal dit door de bestuursleden worden vastgelegd en meegedeeld aan de huidige/nieuwe leden.<br/>Het betaalde lidgeld kan op geen enkel ogenblik of om geen enkele reden teruggevorderd worden.</li>
            <li>Het bestuur heeft het recht om iemand het lidmaatschap te weigeren wanneer blijkt dat iemand met de verkeerde bedoeling lid wil worden van de supportersclub Leuven Lads of reeds lid is van een andere supportersclub van OHL.</li>
            <li>Het bestuur heeft het recht om iemand het lidmaatschap te ontzeggen, indien hij/zij zich niet kan voegen naar het werkings/busreglement. Dit kan zijn tijdens een wedstrijd, maar ook buiten het stadion (op de supportersbus of in het supporterslokaal). <br/>Het bestuur zal bij misdragen de ernst van de zaak inschatten en mogelijks overgaan tot het ontzeggen van het lidmaatschap.</li>
            <li>We beamen en onderschrijven het Reglement Inwendige Orde (RIO), zoals dit beschreven staat op de website van de club Oud-Heverlee Leuven.</li>
            <li>De ingeschreven leden hebben recht op een aantal voordelen. Deze voordelen worden door het bestuur meegedeeld aan de leden via mail en/of nieuwsbrief.</li>
            <li>Tot de leeftijd van 12 jaar moet je vergezeld zijn van een ouder of begeleider om mee te gaan op een uitverplaatsing met de Leuven Lads.</li>
            <li>Wij zijn tegen elke vorm van racisme en elke andere vorm van discriminatie (zoals bvb antisemitisme, homofobie, seksisme, islamfobie,…) en van de ermee gepaard gaande symbolen. Uitingen in welke vorm ook, gedaan door (bestuurs)leden, zullen resulteren in een onmiddelijke intrekking van het lidmaatschap en een levenslange uitsluiting.</li>
            <li>Nieuwe leden dienen zich akkoord te verklaren met het werkings- en busreglement alvorens te kunnen toetreden tot de Leuven Lads.</li>
        </ol>
    </div>
  </Layout>
)

export default Busreglement
